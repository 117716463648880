import { useReportTest } from "@/queries/useReportTest";

export function useUpdatePatmoTest() {
  // 对应 patmo 1.3.0 版本后的 unlock-overview
  const { mutateAsync: reportPatmoTest, isLoading: isReportTestLoading } = useReportTest();
  async function updatePatmoTests() {
    await reportPatmoTest({ step: "1" });
  }
  return {
    updatePatmoTests,
    isLoading: isReportTestLoading,
  };
}
