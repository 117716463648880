import { lazy } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowLeft from "@/assets/arrow-left.svg?react";
import { Button, Popup, PopupAction, PopupMain } from "@/components";
import { useModal } from "@/hooks";
import { GeneralIMEIRegExp, LiteSNRegExp, OneTwoDigitRegExp, PATMOSNRegExp } from "@/shared/regexps";
import { checkVariableType, hasKey, matchesPattern } from "@/shared/scanners";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

const Scanner = lazy(() => import("@/components/scanner/Scanner"));

const ScannerDeviceInfo = () => {
  // 只允许路径和状态不同的页面跳转
  const navigateRRD = useNavigate();
  function navigate(to: string | -1, options?: { state?: any }) {
    if (to === -1) {
      navigateRRD(to);
    } else {
      navigateRRD(to, { state: options?.state, replace: true });
    }
  }

  const { pathname } = useLocation();
  const { visible, toggle } = useModal();
  const device_type = getStorage("deviceType") as DeviceType;

  /**
   * @description 处理扫描成功后的回调函数
   * src/pages/IMEIReplace/components/IMEIReplaceScanner.tsx 参考了这里的代码, 记得检查
   * @param result
   */
  const successFn = (result: string) => {
    let deviceDate;

    try {
      // 二维码数据是JSON对象
      deviceDate = JSON.parse(result);
      // 二维码数据有可能是字符串, 当字符串中全是数字, JSON.parse会将其解析成数字, 数字太多了JS处理精度不足
      if (typeof deviceDate === "number") {
        deviceDate = { sn: result };
      }
      console.debug("resolve succeed.");
    } catch (e) {
      // 二维码数据有可能是字符串
      // 如果扫描结果有误或JSON解析失败，直接将扫描结果作为sn, 在后续的进一步处理中会进行格式校验
      console.debug("[index] [Line 66]: e", e);
      deviceDate = { sn: result };
    }
    try {
      switch (device_type) {
        // Lite 数据格式 1: {sn:string, imei:string}
        case "PabbitLite": {
          // sn
          hasKey(deviceDate, "sn");
          checkVariableType(deviceDate.sn, "string");
          matchesPattern(deviceDate.sn, LiteSNRegExp);

          // imei
          hasKey(deviceDate, "imei");
          checkVariableType(deviceDate.imei, "string");
          matchesPattern(deviceDate.imei, GeneralIMEIRegExp);

          const { sn, imei } = deviceDate;
          navigate("/register/confirm", {
            state: {
              device: { sn, imei },
              pathname,
            },
          });
          break;
        }
        // PATMO 数据格式 1: {aipSN:string, imei:string, gateNo:number}
        // PATMO 数据格式 2: {aipSN:string, imei:string}
        // 以上两种格式的imei都有可能为空字符串但一定有这个字段
        case "PATMO": {
          hasKey(deviceDate, "aipSN");
          checkVariableType(deviceDate.aipSN, "string");
          matchesPattern(deviceDate.aipSN, PATMOSNRegExp);
          hasKey(deviceDate, "imei");
          checkVariableType(deviceDate.imei, "string");
          if (deviceDate.imei !== "") {
            matchesPattern(deviceDate.imei, GeneralIMEIRegExp);
          }
          if (deviceDate?.gateNo) {
            checkVariableType(deviceDate.gateNo, "number");
            matchesPattern(deviceDate.gateNo, OneTwoDigitRegExp);
            const { aipSN, imei, gateNo } = deviceDate;
            navigate("/register/confirm", {
              state: {
                device: { sn: aipSN, imei, gateNumberPATMO: gateNo },
                pathname,
              },
            });
          } else {
            const { aipSN, imei } = deviceDate;
            navigate("/register/confirm", {
              state: {
                device: { sn: aipSN, imei },
                pathname,
              },
            });
          }
          break;
        }
        // Adapter 数据格式 1: {imei:string, ifcSN:stirng}, ifcSN不使用所以不校验
        case "DearisAdapter": {
          // ifcSN
          hasKey(deviceDate, "ifcSN");

          // imei
          hasKey(deviceDate, "imei");
          checkVariableType(deviceDate.imei, "string");
          matchesPattern(deviceDate.imei, GeneralIMEIRegExp);
          const { imei } = deviceDate;
          navigate("/register/manual", {
            state: {
              device: { imei },
              pathname,
            },
          });
          break;
        }
        // dearisController 数据格式 1: {aipSN:string, buildId:number, gateNo:number}
        // dearisController 数据格式 2: string(sn) 在上一步数据处理中处理成 {sn:string}
        case "dearisController": {
          if (deviceDate?.aipSN) {
            checkVariableType(deviceDate.aipSN, "string");
            matchesPattern(deviceDate.aipSN, PATMOSNRegExp);

            checkVariableType(deviceDate.buildId, "number");
            matchesPattern(deviceDate.buildId, OneTwoDigitRegExp);

            checkVariableType(deviceDate.gateNo, "number");
            matchesPattern(deviceDate.gateNo, OneTwoDigitRegExp);

            const { aipSN, buildId, gateNo } = deviceDate;
            const isDearisReplace = getStorage("DearisReplaceIMEI");
            if (isDearisReplace) {
              navigate("/imei-replace/status", {
                state: {
                  device: {
                    sn: aipSN,
                  },
                  pathname,
                },
              });
            } else {
              navigate("/register/confirm", {
                state: {
                  device: {
                    sn: aipSN,
                    buildIdController: buildId,
                    gateNumberController: gateNo,
                  },
                  pathname,
                },
              });
            }
          }

          if (deviceDate?.sn) {
            checkVariableType(deviceDate.sn, "string");
            matchesPattern(deviceDate.sn, PATMOSNRegExp);

            const { sn } = deviceDate;
            const isDearisReplace = getStorage("DearisReplaceIMEI");
            if (isDearisReplace) {
              navigate("/imei-replace/status", {
                state: {
                  device: { sn },
                  pathname,
                },
              });
            } else {
              navigate("/register/confirm", {
                state: {
                  device: { sn },
                  pathname,
                },
              });
            }
          }
          if (!deviceDate?.aipSN && !deviceDate?.sn) {
            throw new Error("数据不全, sn 和 aipSN 必须要有其中一个");
          }
          break;
        }
        // dearisEntrance 数据格式 1: {aipSN:string, buildId:number, gateNo:number, gateKind:"entrance"}
        case "dearisEntrance": {
          hasKey(deviceDate, "aipSN");
          hasKey(deviceDate, "buildId");
          hasKey(deviceDate, "gateNo");
          hasKey(deviceDate, "gateKind");
          if (deviceDate.gateKind !== "entrance") {
            throw new Error("gateKind Error");
          }

          checkVariableType(deviceDate.aipSN, "string");
          matchesPattern(deviceDate.aipSN, PATMOSNRegExp);

          checkVariableType(deviceDate.buildId, "number");
          matchesPattern(deviceDate.buildId, OneTwoDigitRegExp);

          checkVariableType(deviceDate.gateNo, "number");
          matchesPattern(deviceDate.gateNo, OneTwoDigitRegExp);

          const { aipSN, buildId, gateNo } = deviceDate;
          navigate("/register/confirm", {
            state: {
              device: {
                sn: aipSN,
                buildId,
                gateNumber: gateNo,
              },
              pathname,
            },
          });
          break;
        }
        default:
          throw new Error("Unknown DeviceType");
      }
    } catch (e) {
      console.log(e);
      toggle();
    }
  };

  return (
    <>
      <Scanner onSuccess={successFn}>
        <div className="inline-flex items-center text-white" onClick={() => navigate(-1)}>
          <ArrowLeft />
          <span>キャンセル</span>
        </div>
      </Scanner>
      <Popup visible={visible}>
        <PopupMain>要求に合致する情報がないか、情報フォーマットが不適切です。もう一度スキャンしてください。</PopupMain>
        <PopupAction>
          <Button onClick={toggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
};

export default ScannerDeviceInfo;
