import { useNavigate } from "react-router-dom";

import DoorIcon from "@/assets/door.svg?react";
import { Button, Card, Footer, Header, Main, Popup, PopupAction, PopupMain } from "@/components";
import { ConfigPanelContainer, ConfigPanelItem } from "@/components/config-panel";
import { useFetchDataError, useModal } from "@/hooks";
import { useGetDeviceById, useGetDeviceTestResults } from "@/queries";
import { setStorage } from "@/shared/store";

import { CloudUnlockTestModal, Image } from "../components";

const PatmoUnLockTest = () => {
  const navigate = useNavigate();
  const { visible: unlockVisible, toggle: unlockToggle } = useModal();
  const { visible: noSpotVisible, toggle: noSpotToggle } = useModal();

  const { data, isError: isGetDeviceError, error: deviceError } = useGetDeviceById();
  const device = data?.data;
  const { data: testResultData, isError: isGetTestResultError, error: testResultError } = useGetDeviceTestResults();
  useFetchDataError(isGetDeviceError || isGetTestResultError, deviceError || testResultError);
  const testResult = testResultData?.data;
  const isAllTestPassed = !!testResult?.[1] && !!testResult?.[2];

  const handleRoomUnlockTest = () => {
    if (!device?.position?.spot || !device?.position?.image_uri) {
      noSpotToggle();
    } else {
      setStorage("tobeUnlockId", device.id);
      navigate("/deliveries", { replace: true });
    }
  };

  const handleCloudUnlockTest = () => {
    if (!device?.position?.spot || !device?.position?.image_uri) {
      noSpotToggle();
    } else {
      setStorage("tobeUnlockId", device.id);
      unlockToggle();
    }
  };

  return (
    <>
      <Header canBack backText="戻る" onClick={() => navigate(-1)}>
        解錠テスト
      </Header>
      <Main>
        <Card>
          {device?.position?.spot ? (
            <p className="font-medium">
              <DoorIcon className="mr-1 inline" />
              <span>{device.position.spot}</span>
            </p>
          ) : (
            <p className="font-medium text-[#C4CBCC]">未設定</p>
          )}

          <div className="mt-5 flex gap-10 text-sm text-green-gray">
            <div>
              <span>棟番号：</span>
              <span>{device?.position?.build_id ?? "-"}</span>
            </div>
            <div>
              <span>ゲート番号：</span>
              <span>{device?.position?.gate_number || "-"}</span>
            </div>
          </div>
          <Image url={device?.position?.image_uri || ""} className="mt-1" />
        </Card>
        <Card className="mt-5">
          <ConfigPanelContainer>
            <p className="rounded-md bg-[#F6F8F8] p-2 text-xs leading-[18px] text-[#6D7174]">
              {"テスト結果の更新には遅延が生じることがありますので、手動でページを更新する必要があります。"}
            </p>
            <ConfigPanelItem
              className="!border-t-0"
              optionName="番号入力解錠テスト"
              content={testResult?.["1"] ? <>&nbsp;</> : "保留中"}
              onClick={handleRoomUnlockTest}
            />
            <ConfigPanelItem
              optionName="クラウド解錠テスト"
              content={testResult?.["2"] ? <>&nbsp;</> : "保留中"}
              onClick={handleCloudUnlockTest}
            />
          </ConfigPanelContainer>
        </Card>
        <div className="mb-4 mt-10">
          <Button disabled={!isAllTestPassed} onClick={() => navigate(-1)}>
            すべてのテストが完了
          </Button>
        </div>
      </Main>
      <Footer />
      <CloudUnlockTestModal visible={unlockVisible} toggle={unlockToggle} />
      <Popup visible={noSpotVisible}>
        <PopupMain>
          <p className="mb-2 text-lg font-semibold">注意：</p>
          「設置場所」と「写真」の情報がありません。クラウド解錠テストを完了することができません。
        </PopupMain>
        <PopupAction>
          <Button onClick={noSpotToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
};

export default PatmoUnLockTest;
