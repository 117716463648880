import { Link, useNavigate } from "react-router-dom";

import ImagePatmo from "@/assets/patmo-pre-setting-step-one.png";
import ReturnIcon from "@/assets/return.svg?react";
import { Button, DeliveriesCard, DeliveryItem, Footer, Header, Main } from "@/components";
import { Step, StepContainer, StepGraph, StepIndicator, StepParagraph } from "@/components/step";

import { SkipTestBTNPATMO } from "../components";
import { useProvidersForDearis } from "../hooks";

export default function () {
  const navigate = useNavigate();
  const { availableProviders, isProvidersAllUnavailable, isError, isSomeTestable, isLoading, refetch } =
    useProvidersForDearis();

  const handleNextClick = () => {
    navigate("/unlockTest/patmo", { replace: true });
  };

  return (
    <>
      <Header>業者リストの確認</Header>
      <Main>
        <StepContainer>
          {/* Step One */}
          <Step>
            <StepGraph image={ImagePatmo} />
            <StepParagraph>
              <StepIndicator>1</StepIndicator>
              デバイスのホーム画面に戻り、Pabbitのボタンを押してください。
            </StepParagraph>
          </Step>
          {/* Step Two */}
          <Step>
            <StepParagraph>
              <StepIndicator>2</StepIndicator>
              デバイスの宅配業者リストと以下表示された内容が一致していることを確認してください。
            </StepParagraph>
            <StepParagraph>
              <DeliveriesCard refresh={refetch} isFreshing={isLoading} className="!my-0">
                {isError || isProvidersAllUnavailable ? (
                  <DeliveryItem className="text-center text-[#999999]" testable>
                    宅配業者が設定されていません
                  </DeliveryItem>
                ) : (
                  <>
                    {availableProviders.map(({ name, testable }, index) => (
                      <DeliveryItem key={index} testable={testable}>
                        {name}
                      </DeliveryItem>
                    ))}
                  </>
                )}
              </DeliveriesCard>
            </StepParagraph>
            {isError || isProvidersAllUnavailable ? (
              <>
                <StepParagraph className="text-green-gray">
                  <span className="text-error">※ </span>
                  リストがない場合、物件管理者はPabbit Deskに宅配業者が設定されていません。
                </StepParagraph>
                <StepParagraph className="text-error">
                  管理者に連絡して設定を行ってください。そうしないと、解錠テストが完了できなくなります。
                </StepParagraph>
              </>
            ) : (
              <div>
                <p className="text-[15px] text-[#1D2129]">
                  注意：部屋の呼び出しができない場合は入力解錠テストはスキップしてください。
                </p>
                <p className="mt-3 text-sm text-[#86909C]">
                  「※」マークのついているサービス事業者は番号入力解錠テストを行うことが出来ません。
                </p>
              </div>
            )}
          </Step>
          {/* Step Three */}
          <Step>
            {isProvidersAllUnavailable ? (
              <>
                <StepParagraph>
                  <StepIndicator>3</StepIndicator>
                  完了すると、次のステップに進みます。
                </StepParagraph>
                <Button disabled>次へ</Button>
                {/* <Link
                  to="/"
                  className="mb-3 block text-center text-sm leading-[22px]"
                >
                  <ReturnIcon className="inline" />
                  トップページに戻る
                </Link> */}
              </>
            ) : (
              <div>
                <Button onClick={handleNextClick} disabled={!isSomeTestable}>
                  次へ
                </Button>
                <SkipTestBTNPATMO />
              </div>
            )}
          </Step>
          {isProvidersAllUnavailable && (
            <Step className="-mt-4">
              <Link to="/" className="block text-center text-sm leading-[22px] underline">
                <ReturnIcon className="mb-[3px] mr-[6px] inline h-[22px]" />
                トップページに戻る
              </Link>
            </Step>
          )}
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
