import type { AxiosError } from "axios";
import axios from "axios";

import { getCurrentSession } from "./auth";
import { ECONNABORTEDError, ResponseError, Unauthorized401Error } from "./error.type";

const server = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000,
});

server.interceptors.request.use(
  async (request) => {
    if (
      !request.headers.Authorization ||
      !("Authorization" in request.headers) ||
      request.headers.Authorization === "Bearer null" ||
      request.headers.Authorization === "Bearer undefined"
    ) {
      try {
        const cognitoUser = await getCurrentSession();
        const token = cognitoUser.getIdToken().getJwtToken();
        if (!token) {
          throw new Unauthorized401Error("ログインの有効期限が切れました。");
        }
        request.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        if (!["/login", "/login-confirm"].find((route) => window.location.href?.includes(route))) {
          window.location.replace("/login");
          return Promise.reject(new Unauthorized401Error("ログインの有効期限が切れました。"));
        }
        if (request?.url !== "/users") {
          return Promise.reject(new Unauthorized401Error("ログインの有効期限が切れました。"));
        }
      }
    }
    return request;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  }
);

server.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const status = error?.response?.status;
    if (status) {
      switch (status) {
        case 400:
          return Promise.reject(error.response);
        case 401:
          if (!window.location.href.includes("/login") && !window.location.href.includes("/login-confirm")) {
            window.location.replace("/login");
            throw new Unauthorized401Error("アカウントのログインがタイムアウトしました。");
          }
          break;
        case 403:
          return Promise.reject(error.response);
        case 404:
          return Promise.reject(new ResponseError("リソースが見つかりませんでした。エラーコード：404。"));
        case 500:
          return Promise.reject(new ResponseError("サーバーエラー。エラーコード：500。"));
        case 502:
          return Promise.reject(new ResponseError("ネットワークが不安定です。エラーコード：502。"));
        case 503:
          return Promise.reject(new ResponseError("ネットワークが不安定です。エラーコード：503。"));
        default:
          return Promise.reject(new ResponseError("未知のエラー。"));
      }
    } else if (error.code === "ECONNABORTED") {
      // 请求超时 和 timeout 配置有关
      // 请求开始了但是在完成之前被取消了, 连接被中止
      // throw new Error("ネットワークタイムアウト");
      throw new ECONNABORTEDError("ネットワークがタイムアウトし、接続が切断されました。");
    } else if (error.code === "ERR_NETWORK") {
      // 请求无法到达服务器, 网络连接问题或者服务器无法响应
      // throw new Error("ネットワークエラー");
      throw new Error("ネットワーク異常です。リクエストが送信されませんでした。");
    } else if (error.code === "ETIMEDOUT") {
      // 尝试连接到服务器, 但在指定的时间内服务器都没有响应
      // throw new Error("ネットワークタイムアウト");
      throw new Error("サーバーが異常です。");
    } else {
      return Promise.reject(error);
    }
  }
);
export { ECONNABORTEDError, ResponseError };
export default server;
