import { useLocation, useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import { Step, StepContainer, StepParagraph, StepsGenerator } from "@/components/step";

import { ServiceConfigDoneBTN } from "../components";
import {
  patmoStepsData_has_imei_1,
  patmoStepsData_has_imei_2,
  patmoStepsData_has_wire_1,
  patmoStepsData_has_wire_2,
} from "./patmo-steps-data";

export default function ServiceConfigure() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasImei = state?.hasImei;
  return (
    <>
      <Header>サービス設定</Header>
      <Main>
        <StepContainer>
          {hasImei ? (
            <>
              <StepsGenerator stepsData={patmoStepsData_has_imei_1} />
              <StepParagraph>
                <span className="mr-1 text-primary">❸</span>
                このページに戻ったら、「スキャナー接続テスト」を押してください。
              </StepParagraph>
              <Step>
                <Button onClick={() => navigate("/scanner-test")}>スキャナーテストを開始する</Button>
              </Step>
              <StepsGenerator stepsData={patmoStepsData_has_imei_2} />
            </>
          ) : (
            <>
              <StepsGenerator stepsData={patmoStepsData_has_wire_1} />
              <StepsGenerator stepsData={patmoStepsData_has_wire_2} />
            </>
          )}
          <ServiceConfigDoneBTN />
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
