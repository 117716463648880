import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getPropertyById, unlockDeviceOfProperty } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError, PositionNotExistsError } from "./errors.type";

export function useAmazonUnlockTest() {
  const mutationFn = async (device_id: string | undefined) => {
    const property_id = getStorage("propertyId");
    const room_number = getStorage("roomNumber");

    if (!property_id || !device_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }

    if (room_number) {
      const property = await getPropertyById(property_id);
      const rooms = property?.data?.buildings?.map((building) => building?.rooms).flat();
      const theRoom = rooms?.find((room) => room?.number === room_number);
      if (!theRoom) {
        throw new PositionNotExistsError("部屋番号が存在しません");
      }
    }
    return unlockDeviceOfProperty({
      property_id,
      device_id,
      test_flag: 1,
      room_number,
    }).catch((error) => {
      const errorMessage = error.data;
      const errorStatus = error.status;
      if (errorStatus === 403 && errorMessage.type === "UNLOCK_DENIED") {
        throw new Error("運用モードのデバイスでは解錠テストを行うことができません。");
      } else {
        throw error;
      }
    });
  };
  return useMutation(mutationFn);
}
